import React from "react";
import service_1 from "../../assets/service_1.jpg";
import service_2 from "../../assets/service_2.jpg";
import service_3 from "../../assets/service_3.jpg";
import Form from "./Form";
import Tags from "./Tags";
import { FaCheckCircle, FaFontAwesome, FaSearch } from "react-icons/fa";

const CardSection = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center gap-12 my-7">
      <div className="flex flex-col w-full md:w-1/2 mt-20 mx-auto">
        <div className="mx-4">
          <img src={service_1} alt="Service1" />
        </div>
        <div className="flex flex-col gap-y-3 mt-6 mx-4">
          <p className=" text-3xl md:text-5xl font-serif font-medium">
            Body Massages
          </p>
          <p className=" text-md text-black text-justify">
            A body massage is a holistic therapeutic practice focused on
            manipulating muscles and soft tissues to induce relaxation,
            alleviate muscle tension, and enhance overall well-being. It
            encompasses a variety of techniques, including Swedish massage for
            general relaxation, deep tissue massage for targeting specific
            muscle groups, and aromatherapy massage, which incorporates
            essential oils to further enhance the sensory experience.
          </p>
          <p className="text-md text-black text-justify">
            Tailored to individual needs and preferences, a body massage session
            offers not only physical benefits such as improved circulation and
            flexibility but also mental and emotional relaxation, leaving
            individuals with a profound sense of rejuvenation and a restored
            sense of balance.
          </p>
        </div>
        <div className="flex flex-col gap-y-3 mt-6 mx-4">
          <p className="md:text-3xl  font-serif font-medium">
            Benefit For Body Massage
          </p>
          <p className=" text-md text-black text-justify">
            A body massage offers a myriad of holistic benefits that contribute
            to overall well-being. Through the skilled manipulation of muscles
            and soft tissues, massage serves as a powerful stress-reliever,
            reducing cortisol levels and inducing a deep sense of relaxation.
            Beyond stress reduction, it alleviates muscle tension, enhancing
            flexibility and promoting improved circulation throughout the body.
            The physical benefits extend to pain relief, better sleep quality,
            and a strengthened immune system.
          </p>
        </div>
        <div className="flex flex-col md:flex-row mt-6 gap-12">
          <div className="flex flex-row items-center gap-3 mx-auto">
            <FaCheckCircle />
            <p>Best For Human Health</p>
          </div>
          <div className="flex flex-row items-center gap-3 mx-auto">
            <FaCheckCircle />
            <p>Always Be Strong & Motivate</p>
          </div>
          <div className="flex flex-row items-center gap-3 mx-auto">
            <FaCheckCircle />
            <p>Hair loss prevention</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-7 mt-6 mx-4">
          <img src={service_2} alt="Service2" />
          <img src={service_3} alt="Service3" />
        </div>
        <div className="mt-6 mx-4">
          <p className="text-md text-black text-justify">
            Simultaneously, massage contributes to emotional wellness by
            releasing endorphins, elevating mood, and reducing anxiety and
            depression. The practice fosters a mind-body connection, promoting
            mindfulness and relaxation. With its positive impact on skin health,
            posture, and even hormonal balance, regular body massages become a
            cornerstone of a comprehensive wellness routine, providing
            individuals with a rejuvenating and revitalizing experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-3 mt-6 mx-4">
          <p className="md:text-3xl font-serif font-medium">
            Massage Techniques
          </p>
          <p className="text-md text-black text-justify">
            For an effective body massage, start with a brief consultation to
            understand the client's needs and preferences. Create a calming
            environment with soft lighting and soothing music. During the
            massage, use techniques like effleurage and petrissage, adjusting
            pressure based on client feedback. Pay attention to sensitive areas
            and avoid excessive pressure on joints. Use quality massage oils for
            smooth movements. Maintain a steady rhythm and flow, respecting
            client boundaries. Post-massage, allow time for relaxation and offer
            any relevant post-care advice. Continuous education in various
            techniques ensures a consistently high-quality experience.
          </p>
        </div>
      </div>
      <div className="flex flex-col mx-auto">
        <div className="bg-primaryBgColor rounded-md p-16 shadow-md mb-4 mt-8 mx-2">
          <div className="flex flex-col ">
            <div>
              <p className="font-serif text-2xl">Categories</p>
              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[3px] bg-black"></div>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Body Treatments</p>
                </div>
                <p>(8)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] primaryBgColor"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Facials</p>
                </div>
                <p>(6)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] primaryBgColor"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Massages</p>
                </div>
                <p>(5)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] primaryBgColor"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Nail Art</p>
                </div>
                <p>(7)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] primaryBgColor"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Waxing</p>
                </div>
                <p>(2)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] primaryBgColor"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Hair care</p>
                </div>
                <p>(9)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] primaryBgColor"></div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='mx-2'>
                    <Form />
                </div> */}

        <div className="mx-2">
          <Tags />
        </div>

        <div className="bg-primaryBgColor rounded-md p-4 md:py-8 md:px-8 shadow-md mb-4 mt-8">
          <div className="flex flex-col items-center">
            <p className="font-serif text-2xl mb-3">Features</p>
            <div className="flex w-full mt-2 justify-center">
              <div className="w-full h-1 bg-black"></div>
            </div>
          </div>

          <div className="flex flex-col justify-center mt-3">
            <div className="mt-3 flex flex-wrap justify-center">
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 
                    mb-2 transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Bridal Makeup
              </button>
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Celebration
              </button>
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Groom Makeup
              </button>
            </div>

            <div className="mt-3 flex flex-wrap justify-center">
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Wedding Makeup
              </button>
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Hair cuts
              </button>
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Facial
              </button>
            </div>

            <div className="mt-3 flex flex-wrap justify-center">
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 
                    mb-2 transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Scrubing
              </button>
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Celebration
              </button>
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Massages
              </button>
            </div>

            <div className="mt-3 flex flex-wrap justify-center">
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Nail Art
              </button>
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Hair Styling
              </button>
              <button
                className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Body Spa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
