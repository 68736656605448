import React from "react";
import { FaSearchLocation, FaPhoneSquare, FaClock } from "react-icons/fa";

const Card = () => {
  return (
    <div>
      <div className="w-full md:w-[70%] mt-8 md:mt-24 mb-4 md:mb-16  md:ml-36 rounded-md">
        <div className="flex flex-col items-center justify-center mt-8 mb-8 mx-6">
          <div className="mt-6">
            <p className="font-serif text-3xl mb-4 text-center md:text-left tracking-wider">
              Our Contact Information
            </p>
          </div>
          <div className="mt-3">
            <p className="text-primaryColor text-center md:text-left tracking-wide">
              Have an inquiry or some feedback for us? Fill out the form below
              to contact our team.
            </p>
          </div>

          <div className="flex flex-col md:flex-col tracking-wide ">
            <div className="bg-primaryBgColor rounded-md p-8 shadow-md mb-4 mt-8  md:mt-8  flex-shrink-0">
              <div className="flex flex-row items-center">
                <FaSearchLocation className="text-2xl p-4 bg-orange-200 text-white h-16 w-16 mr-6 rounded-md" />
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">Our Address</p>
                  <p className="text-primaryColor">
                    Marwari Para, Jharsuguda, Odisha, 768201
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-primaryBgColor rounded-md p-8 shadow-md mb-4 flex-shrink-0 tracking-wide">
              <div className="flex flex-row items-center">
                <FaPhoneSquare className="text-2xl p-4 bg-orange-200 text-white h-16 w-16 mr-6 rounded-md" />
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">Our Phone</p>
                  <p className="text-primaryColor">+91 7327986868</p>
                  <p className="text-primaryColor">kayaspa23@gmail.com</p>
                </div>
              </div>
            </div>

            <div className="bg-primaryBgColor rounded-md p-8 shadow-md mb-8 flex-shrink-0 tracking-wide">
              <div className="flex flex-row items-center">
                <FaClock className="text-2xl p-4 bg-orange-200 text-white h-16 w-16 mr-6 rounded-md" />
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">Working Hours</p>
                  <p className="text-primaryColor">Mon-Fri: 11:00 AM - 09:00 PM</p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
