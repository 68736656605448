import React from 'react'

const Tags = () => {
    return (
        <div className='bg-primaryBgColor rounded-md p-4 md:py-8 md:px-8 shadow-md mb-4 mt-8'>
            <div className='flex flex-col items-center'>
                <p className='font-serif text-2xl mb-3'>Tags</p>
                <div className="flex w-full mt-2 justify-center">
                    <div className="w-full h-1 bg-black"></div>
                </div>
            </div>

            <div className="flex flex-col justify-center mt-3">
                <div className='mt-3 flex flex-wrap justify-center'>
                    <button
                        className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 
                    mb-2 transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold">
                        Bride
                    </button>
                    <button
                        className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold">
                        Celebration
                    </button>
                    <button className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold">
                        Groom
                    </button>
                </div>

                <div className='mt-3 flex flex-wrap justify-center'>
                    <button className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold">
                        Wedding
                    </button>
                    <button className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold">
                        Photo
                    </button>
                    <button className="bg-swipeToBgColor text-subHeadingColor px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold">
                        Dress
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Tags