import React, { useEffect, useState } from "react";
import Cards from "../components/Homepage/Cards";
import CTAButton from "../components/Homepage/Button";
import { FaArrowRight } from "react-icons/fa";
import shape from "../assets/shape.svg";
import Card from "../components/Homepage/Card";
import massage from "../assets/massage.jpg";
import girl from "../assets/girl.jpg";
import rightarr from "../assets/rightarr.svg";
import badge from "../assets/badge.svg";
import leaf from "../assets/leaf.svg";
import like from "../assets/like.svg";
import user from "../assets/user.svg";
import girls from "../assets/girls.svg";
import Expert from "../components/Homepage/Expert";
import Form from "../components/Homepage/Form";
import brand_1 from "../assets/brand_1.png";
import brand_2 from "../assets/brand_2.png";
import brand_3 from "../assets/brand_3.png";
import brand_4 from "../assets/brand_4.png";
import CardSection from "../components/Homepage/CardSection";
import PriceCard from "../components/Homepage/PriceCard";
import formimage from "../assets/formImage.png";
import "./HomePage.css";

import image1 from "../assets/woman-pretty.webp";
import image2 from "../assets/woman-doing-makeup.webp";
import image3 from "../assets/woman-face-with-fashion.webp";
import { motion } from "framer-motion";

const images = [image1, image2, image3];

const starsComponent = () => {
  return (
    <div>
      {[1, 2, 3, 4, 5, 6].map(() => (
        <>
          <div
            className="star star-blue"
            style={{
              animation: "blowStars 4s linear infinite",
              animationDelay: "1s",
            }}
          ></div>
          <div
            className="star star-yellow"
            style={{
              animation: "blowStars 5s linear infinite",
              animationDelay: "3s",
            }}
          ></div>
          <div
            className="star star-white"
            style={{
              animation: "blowStars 6s linear infinite",
              animationDelay: "2s",
            }}
          ></div>
          <div
            className="star star-pink"
            style={{
              animation: "blowStars 7s linear infinite",
              animationDelay: "2.5s",
            }}
          ></div>
          <div
            className="star star-green"
            style={{
              animation: "blowStars 8s linear infinite",
              animationDelay: "1.5s",
            }}
          ></div>
        </>
      ))}
    </div>
  );
};

const Home = () => {
  return (
    <div>
      <div className="w-full h-fit flex flex-col md:flex-row items-center justify-evenly bg-gradient-to-r from-swipeToBgColor to-primaryBgColor overflow-x-hidden relative">
        <div className="relative w-full md:w-1/2 mt-8 md:mt-0 mx-4 md:mx-12 overflow-hidden order-last md:order-first">
          <img
            src={image1}
            className="object-cover w-full h-[300px] md:h-[450px] mt-4 md:mt-12"
            alt="Women"
          />
        </div>

        <div className="flex flex-col items-center justify-center w-full md:w-1/2">
          <div className="mt-5">
            <p className="text-3xl md:text-7xl text-center font-semibold tracking-wider animate-pulse">
              Elevate <span className="text-primaryColor">Physically</span> And
              Mentally With <span className="text-primaryColor">Our Spa</span>{" "}
              Massages
            </p>
          </div>

          <div className="flex flex-col md:flex-row gap-7 text-white mt-12 items-center md:items-start">
            <button className="mb-4 transform hover:scale-95 transition duration-200 ease-in-out tracking-wider flex justify-center items-center gap-2 text-sm px-6 py-3 rounded-md font-bold bg-primaryColor">
              EXPLORE SERVICES <FaArrowRight />
            </button>
          </div>
        </div>
      </div>

      {/*Section 2*/}
      <div className="py-6 w-full h-fit flex flex-col md:flex-row justify-evenly bg-gradient-to-r from-gray-200 to-gray-100">
        <div className="flex flex-col items-center mx-auto">
          <div className="">
            <p className="text-primaryColor font-sm text-xl font-serif text-center md:text-left">
              What We do..?
            </p>
          </div>

          <div className="mt-4">
            <img src={shape} alt="Shape" />
          </div>

          <div className="text-black text-center md:text-left text-4xl font-serif my-4 tracking-wider">
            Professional Spa and Beauty Services
          </div>

          <Card />
        </div>
      </div>

      {/*Section 3*/}
      <div
        className="w-full h-fit
                  flex flex-col md:flex-row justify-evenly
                  bg-gradient-to-r from-primaryBgColor to-swipeToBgColor  gap-12 overflow-x-auto"
      >
        <div className="relative mt-8 mb-20 mx-4 md:ml-40 md:mr-10 md:w-full">
          <img
            src={massage}
            alt="Massage"
            className="object-contain w-full h-full md:w-auto md:h-auto"
          />

          <img
            src={girl}
            alt="Girl"
            className="object-contain w-[350px] h-[330px] 
                       absolute top-40 -mr-72 left-[250px] hidden md:block"
            style={{ zIndex: 1 }}
          />
        </div>

        <div className="flex flex-col items-center justify-center mx-4 md:mx-10 md:w-full">
          <div className="flex flex-row items-center gap-3">
            <div>About Us</div>

            <div>
              <img src={rightarr} alt="Right Arr" />
            </div>
          </div>

          <div className="mt-6">
            <p className="text-3xl md:text-4xl font-extrabold tracking-wide leading-10">
              Discover The{" "}
              <span className="text-primaryColor">Ultimate Spa</span> Massage
              Experience
            </p>
          </div>

          <div className="text-subHeadingColor text-base md:text-lg text-justify mt-6">
            Discover a world of tranquility, luxury, and personalized care as
            you embark on a journey of self-care and renewal. Visit us today and
            let us transport you to a realm of beauty and serenity
          </div>

          <div className="text-subHeadingColor text-base md:text-lg my-6">
            We also offer private rooms for more intimate treatments and
            couples' packages for a romantic escape
          </div>
        </div>
      </div>

      {/*Section 4*/}
      <div
        className="w-full h-fit
                  flex flex-col md:flex-row justify-evenly
                 bg-gradient-to-r from-gray-200 to-gray-100  gap-12 overflow-x-auto"
      >
        <div className="flex flex-col">
          <div className="mt-12 flex flex-col md:flex-row md:gap-12 lg:gap-36 ">
            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:mr-4">
                <img src={badge} alt="Badge" />
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div>
                  <p className="text-5xl">26 +</p>
                </div>
                <div className="mt-2">
                  <p className="text-black text-md">
                    Years of Experience
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:mr-4">
                <img src={leaf} alt="Leaf" />
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div>
                  <p className="text-5xl">100 %</p>
                </div>
                <div className="mt-2">
                  <p className="text-black text-md">
                    Natural Products
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:mr-4">
                <img src={like} alt="Like" />
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div>
                  <p className="text-5xl">2000 +</p>
                </div>
                <div className="mt-2">
                  <p className="text-black text-md">
                    Satisfied Clients
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:mr-4">
                <img src={user} alt="User" />
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div>
                  <p className="text-5xl">36 +</p>
                </div>
                <div className="mt-2">
                  <p className="text-black text-md">
                    Specialists Team
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="items-center justify-center flex my-10">
            <div className="w-full  h-[2px] bg-primaryColor"></div>
            <img
              src={girls}
              alt="Girls"
              className="bg-red-200 w-24 rounded-full"
            />
            <div className="w-full  h-[2px] bg-primaryColor"></div>
          </div>

          <div>
            <div className="text-black text-center text-4xl font-serif tracking-wider">
              Our Latest Gallery
            </div>

            <div className="mt-12 mb-20">
              <Cards />
            </div>
          </div>
        </div>
      </div>

      {/*Section 4*/}
      <div>
        <Expert />
      </div>

      {/*Section 5*/}
      <div className="mt-36 bg-gradient-to-r from-gray-200 to-gray-100 overflow-x-hidden">
        <PriceCard />
      </div>

      {/*Form Section */}
      <div className="bg-gradient-to-r from-primaryBgColor to-swipeToBgColor py-6">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-evenly px-4 md:px-6 lg:px-8">
          <div className="flex flex-col w-full md:w-[75%] lg:w-[50%] xl:w-[38%] items-center text-center md:text-left">
            <h2 className="mt-7 mb-4 text-3xl font-bold tracking-wider">
              "Spa moments"
            </h2>
            <p className="text-lg tracking-wide text-subHeadingColor">
            In the sanctuary of spa moments, where time dances with serenity,
        whispers of tranquility delicately weave a poetic tapestry on the canvas
        of the body. Each gentle touch, a masterful stroke of rejuvenation,
        paints strokes of self-love, creating an immersive experience where the
        soul finds its harmonious resonance. Here, in the quietude of pampering,
        the body becomes a sacred canvas, adorned with the artistry of
        well-being, and every nuanced detail unfolds as a chapter in a lyrical
        narrative of self-care, inviting one to embrace the beauty of relaxation
        and the poetry that unfolds within
            </p>
          </div>
          <img src={formimage} alt="FormImage" className="mt-4 md:mt-0 w-full md:max-w-[25%] lg:max-w-[20%] xl:max-w-[15%] object-cover" />
        </div>
      </div>


      {/* Section 7*/}
      <div className="mb-5">
        <CardSection />
      </div>

      {/* Section 8 */}
      <div className="flex flex-col md:flex-row justify-evenly items-center my-auto bg-gradient-to-r from-primaryBgColor to-swipeToBgColor w-full">
        <div className="mt-10 md:mt-20 mb-10 md:mb-20">
          <img
            src={brand_1}
            alt="Brand_1"
            className="w-full md:w-auto max-h-20 md:max-h-full"
          />
        </div>

        <div className="mt-10 md:mt-20 mb-10 md:mb-20">
          <img
            src={brand_2}
            alt="Brand_2"
            className="w-full md:w-auto max-h-20 md:max-h-full"
          />
        </div>

        <div className="mt-10 md:mt-20 mb-10 md:mb-20">
          <img
            src={brand_3}
            alt="Brand_3"
            className="w-full md:w-auto max-h-20 md:max-h-full"
          />
        </div>

        <div className="mt-10 md:mt-20 mb-10 md:mb-20">
          <img
            src={brand_4}
            alt="Brand_4"
            className="w-full md:w-auto max-h-20 md:max-h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
