import React from "react";
import smiley_girl from "../../assets/lds-barber.jpg";

const Expert = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center">
      {/* Image Section */}
      <div className="w-full md:w-1/2">
        <img src={smiley_girl} alt="Smiley Girl" className="w-full h-auto" />
      </div>

      {/* Text Content Section */}
      <div className="w-full md:w-1/2 bg-black text-white p-4 md:p-8">
        <div className="mt-8 md:mt-0">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Our Experience Specialist</h2>
          <p className="mb-8">
            At our spa, our Experience Specialists stand as the custodians of serenity, meticulously crafting each encounter to align with your individual wellness journey. These seasoned professionals, with their extensive training and innate passion for healing, harness the transformative power of touch, aromatherapy, and mindful techniques to guide you towards profound relaxation and revitalization. They are not just experts in their craft; they are your compassionate partners, dedicated to awakening your senses, nurturing your spirit, and restoring harmony to your body and mind. With every carefully curated experience, they aim to elevate your well-being, leaving you feeling balanced, rejuvenated, and deeply connected with your inner self. Trust in their expertise to transform your spa visit into a memorable sanctuary of peace and wellness.
          </p>
        </div>

        <div>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Unveil Serenity with Our Spa Virtuosos</h2>
          <p>
            Step into a world where tranquility meets expertise, and let our Spa Virtuosos lead you to a realm of unparalleled relaxation and rejuvenation. Our team, composed of seasoned specialists, stands at the forefront of holistic wellness, offering a sanctuary for those seeking solace and invigoration. Each specialist brings a unique blend of skill, intuition, and passion to the table, ensuring every therapy is a masterpiece of relaxation. With a deep understanding of the body’s harmonies and the power of touch, they craft personalized sessions that not only soothe your muscles but also elevate your spirit. Join us on a journey of discovery, where each session is an intimate exploration of your well-being, guided by the skilled hands of our Spa Virtuosos.
          </p>
        </div>
      </div>
    </div>


  );
};

export default Expert;
