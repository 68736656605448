import React from 'react'
import { ImCross } from "react-icons/im";

const ModalForm = ({ isOpen, onClose }) => {
  
  return (
    <div>
    {isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="container">
          <div className="flex justify-center">
            <div className="w-full max-w-lg">
              <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  {/* Background Image */}
                  <div className="hidden md:block bg-cover bg-center bg-no-repeat h-80" style={{ backgroundImage: "url('https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp')" }}>
                  </div>
                  {/* Modal Content */}
                  <div className="p-4 md:p-6 flex items-center">
                    <div>
                      <div className="flex items-center mb-3 pb-1">
                        <i className="fas fa-cubes fa-2x me-3 text-primaryBgColor0"></i>
                        <span className="text-2xl font-bold mb-0">Logo</span>
                      </div>
                      <h5 className="font-normal mb-3 pb-3 text-lg">Sign into your account</h5>
                      <div className="mb-4">
                        <input type="email" id="form2Example17" className="form-input" placeholder="Email address" />
                      </div>
                      <div className="mb-4">
                        <input type="password" id="form2Example27" className="form-input" placeholder="Password" />
                      </div>
                      <div className="mb-4">
                        <button className="bg-gray-800 text-white px-4 py-2 rounded-lg w-full" type="button">Login</button>
                      </div>
                      <a href="#!" className="text-sm text-subHeadingColor">Forgot password?</a>
                      <p className="mb-2 text-sm text-gray-700">Don't have an account? <a href="#!" className="text-blue-600">Register here</a></p>
                      <a href="#!" className="text-sm text-subHeadingColor">Terms of use.</a>
                      <a href="#!" className="text-sm text-subHeadingColor">Privacy policy</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Close button */}
        <button onClick={onClose} className="absolute top-0 right-0 m-4 text-subHeadingColor hover:text-subHeadingColor">
          <ImCross size={24} />
        </button>
      </div>
    )}
  </div>
   
  );
};

export default ModalForm