import React from "react";
import shape from "../../assets/shape.svg";
import BlogCard from "./BlogCard";
import blog_1 from "../../assets/blog_1.jpg";
import blog_2 from "../../assets/blog_2.jpg";
import blog_3 from "../../assets/blog_3.jpg";

const CardSection = () => {
  return (
    <div>
      <div className="flex flex-col items-center mt-7 mx-auto">
        <div className="text-primaryColor font-sm text-xl font-serif text-center md:text-left">
          Our Blog
        </div>

        <div className="mt-4">
          <img src={shape} alt="Shape" />
        </div>

        <div className="mt-7 mb-12">
          <p className="text-center text-black text-3xl font-semibold tracking-wider">
            Our Latest News & Blogs
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row mb-10 mx-4">
        <BlogCard
          imageSrc={blog_1}
          date="15"
          author="Alex Michel"
          category="Beauty"
          title="Maintaining Health and Beauty Through Spa"
        />

        <BlogCard
          imageSrc={blog_2}
          date="16"
          author="Alex Michel"
          category="Beauty"
          title="A Relaxation of the senses with Their Help"
        />

        <BlogCard
          imageSrc={blog_3}
          date="10"
          author="Alex Michel"
          category="Beauty"
          title="Ultra Glam Gold Nail polishes for Holiday"
        />
      </div>
    </div>
  );
};

export default CardSection;
