import React from "react";
import spa from "../../assets/kaya-spa-jharsuguda-logo.png";

import {
  FaFacebookSquare,
  FaInbox,
  FaInstagramSquare,
  FaLinkedinIn,
  FaPhoneSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <div className="bg-topBarAndFooterColor">
      <div className="flex gap-4 justify-between flex-wrap items-center p-12">
        <div className="flex flex-col items-center md:items-start">
          <img src={spa} alt="logo" className="w-32 h-32" />
        </div>

        <div className="flex flex-col items-center md:items-start">
          <p className="text-xl md:text-2xl lg:text-3xl font-semibold text-white">Contact Details</p>
          <div className="mt-3">
            <p className="flex items-center text-white text-sm md:text-base">
              <FaPhoneSquare className="mr-3 text-lg md:text-xl" /> +91 7327986868
            </p>
            <p className="mt-4 flex items-center text-white text-sm md:text-base">
              <FaInbox className="mr-3 text-lg md:text-xl" /> kayaspa23@gmail.com
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center md:items-start">
          <p className="text-xl md:text-2xl lg:text-3xl font-semibold text-white">Availability</p>
          <p className="mt-3 text-white text-sm md:text-base">We Are Available:</p>
          <p className="text-gray-500 text-xs md:text-sm">Mon-Sun: 11.00 am to 09.00 pm</p>
          <div className="flex gap-4 mt-8">
            <Link to="https://www.facebook.com/Kayaspajharsuguda?mibextid=ZbWKwL" target="_blank">
              <FaFacebookSquare className="w-6 h-6 md:w-8 md:h-8 text-white" />
            </Link>
            <FaTwitterSquare className="w-6 h-6 md:w-8 md:h-8 text-white" />
            <FaLinkedinIn className="w-6 h-6 md:w-8 md:h-8 text-white" />
            <Link to="https://www.instagram.com/kayaspa7?igsh=MWNhd2piZDN4eDc5Zw==" target="_blank">
              <FaInstagramSquare className="w-6 h-6 md:w-8 md:h-8 text-white" />
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-swipeToBgColor to-primaryBgColor flex justify-between items-center px-4 py-4 md:px-20 h-16">
        <p className="text-white text-sm md:text-base">
          ©{new Date().getFullYear()} Kaira Spa - Jharsuguda
        </p>
        <p className="text-white text-sm md:text-base">
          Design & Develop by
          <Link to="https://webbocket.com/" target="_blank" rel="noopener noreferrer" className="underline font-bold">
            Web_Bocket Pvt. Ltd.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
