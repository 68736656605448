import React from "react";
import rightarr from "../../assets/rightarr.svg";
import choose_1 from "../../assets/choose_1.png";
import choose_2 from "../../assets/choose_2.svg";
import choose_3 from "../../assets/choose_3.svg";
import choose_4 from "../../assets/choose_4.svg";

const Experience = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center bg-gradient-to-r from-primaryBgColor to-swipeToBgColor mb-20 px-4 md:px-8 lg:px-20">
      <div className="w-full md:w-1/2 mb-10">
        <div className="flex flex-row items-center gap-2">
          <div className="mt-6 md:mt-20">
            <p className="text-primaryColor font-serif text-xl md:text-2xl font-medium">
              Why Choose Us
            </p>
          </div>
          <div className="mt-6 md:mt-20">
            <img src={rightarr} alt="Right Arr" className="w-6 h-6 md:w-8 md:h-8" />
          </div>
        </div>
        <div className="mt-6 md:mt-8">
          <p className="text-3xl md:text-4xl lg:text-5xl font-serif text-black tracking-wider">
            We Have <span className="text-primaryColor">25 Years</span> Of Experience With Body Spa.
          </p>
        </div>
        <div className="mt-8">
          <p className="text-subHeadingColor text-base md:text-lg">
            By prioritizing hygiene practices and implementing rigorous sanitation procedures, a body spa can create a safe and hygienic environment that promotes the well-being of both clients and staff.
          </p>
        </div>

        <div className="flex flex-col mt-12 gap-y-8">
          <div className="flex flex-col md:flex-row gap-6 md:gap-10">
            <div className="flex items-center justify-center">
              <img src={choose_2} alt="Choose2" className="w-24 h-24 md:w-32 md:h-32" />
            </div>
            <div className="flex flex-col">
              <p className="text-2xl font-serif">Brand Body Spa Equipment</p>
              <p className="text-subHeadingColor mt-3 md:w-[70%] text-base md:text-lg">
                Adjustable stools and chairs are essential for therapists to maintain proper body mechanics during treatments.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6 md:gap-10">
            <div className="flex items-center justify-center">
              <img src={choose_3} alt="Choose3" className="w-24 h-24 md:w-32 md:h-32" />
            </div>
            <div className="flex flex-col">
              <p className="text-2xl font-serif">Best Place</p>
              <p className="text-subHeadingColor mt-3 md:w-[70%] text-base md:text-lg">
                Best spas often offer curated wellness packages that combine multiple treatments to provide a comprehensive and holistic approach to health and relaxation.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6 md:gap-10">
            <div className="flex items-center justify-center">
              <img src={choose_4} alt="Choose4" className="w-24 h-24 md:w-32 md:h-32" />
            </div>
            <div className="flex flex-col">
              <p className="text-2xl font-serif">Special Support</p>
              <p className="text-subHeadingColor mt-3 md:w-[70%] text-base md:text-lg">
                Have a responsive and knowledgeable customer support team that can address inquiries, feedback, and concerns promptly, contributing to a positive overall experience.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/2 flex justify-center md:justify-end">
        <img src={choose_1} alt="Choose1" className="w-full md:w-3/4 lg:w-1/2 max-w-md" />
      </div>
    </div>

    // <div className="flex flex-col md:flex-row items-center justify-center bg-gradient-to-r from-primaryBgColor to-swipeToBgColor mb-20">
    //   <div className="w-full md:w-1/2 mb-10">
    //     <div className="flex flex-row items-center gap-2 ml-4">
    //       <div className="mt-6 md:mt-20">
    //         <p className="text-primaryColor font-serif text-xl font-md">
    //           Why Choose Us
    //         </p>
    //       </div>
    //       <div className="mt-6 md:mt-20 mr-4">
    //         <img src={rightarr} alt="Right Arr" />
    //       </div>
    //     </div>
    //     <div className="mt-6 ml-4 w-full md:w-[80%]">
    //       <p className="text-3xl md:text-5xl font-serif text-black tracking-wider">
    //         We Have <span className="text-primaryColor">25 Years</span> Of
    //         Experience With Body Spa.
    //       </p>
    //     </div>
    //     <div className="mt-8 w-full md:w-[80%]">
    //       <p className="text-subHeadingColor text-base">
    //         By prioritizing hygiene practices and implementing rigorous
    //         sanitation procedures, a body spa can create a safe and hygienic
    //         environment that promotes the well-being of both clients and staff.
    //       </p>
    //     </div>

    //     <div className="flex flex-col mt-12 gap-y-8">
    //       <div className="flex flex-col md:flex-row gap-10">
    //         <div className="flex items-center justify-center">
    //           <img src={choose_2} alt="Choose2" />
    //         </div>
    //         <div className="flex flex-col">
    //           <p className="text-2xl font-serif">Brand Body Spa Equipment</p>
    //           <p className="text-subHeadingColor mt-3 md:w-[70%]">
    //             Adjustable stools and chairs are essential for therapists to
    //             maintain proper body mechanics during treatments.
    //           </p>
    //         </div>
    //       </div>
    //       <div className="flex flex-col md:flex-row gap-10">
    //         <div className="flex items-center justify-center">
    //           <img src={choose_3} alt="Choose3" />
    //         </div>
    //         <div className="flex flex-col">
    //           <p className="text-2xl font-serif">Best Place</p>
    //           <p className="text-subHeadingColor mt-3 md:w-[70%]">
    //             Best spas often offer curated wellness packages that combine
    //             multiple treatments to provide a comprehensive and holistic
    //             approach to health and relaxation.
    //           </p>
    //         </div>
    //       </div>
    //       <div className="flex flex-col md:flex-row gap-10">
    //         <div className="flex items-center justify-center">
    //           <img src={choose_4} alt="Choose4" />
    //         </div>
    //         <div className="flex flex-col">
    //           <p className="text-2xl font-serif">Special Support</p>
    //           <p className="text-subHeadingColor mt-3 md:w-[70%]">
    //             Have a responsive and knowledgeable customer support team that
    //             can address inquiries, feedback, and concerns promptly,
    //             contributing to a positive overall experience.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div>
    //     <img src={choose_1} alt="Choose1" />
    //   </div>
    // </div>
  );
};

export default Experience;
