import React, { useState } from "react";
import { FaDotCircle } from "react-icons/fa";

const cardsection = [
  {
    heading: "Body Massages",
    para: "Pay attention to sensitive areas and avoid excessive pressure on joints. Use quality massage oils for smooth movements. ",
  },
  {
    heading: "Oil Therapy",
    para: "Oil therapy applies natural oils to the body, promoting muscle relaxation and skin nourishment.",
  },
  {
    heading: "Aroma Therapy",
    para: "Aromatherapy harnesses the therapeutic scents of essential oils to promote relaxation, enhance mood, and improve overall well-being.",
  },
  {
    heading: "Waxing",
    para: "Waxing is a hair removal technique that involves applying and removing wax to eliminate unwanted hair from the root.",
  },
  {
    heading: "Nail Manicure",
    para: "Wheather Youre seeking a rejuvenating facial,a soothing massage body treatment",
  },
  {
    heading: "Body Massages",
    para: "Wheather Youre seeking a rejuvenating facial,a soothing massage body treatment",
  },
  {
    heading: "Waxing",
    para: "Wheather Youre seeking a rejuvenating facial,a soothing massage body treatment",
  },
  {
    heading: "Aroma Therapy",
    para: "Wheather Youre seeking a rejuvenating facial,a soothing massage body treatment",
  },
  {
    heading: "Oil Therapy",
    para: "Wheather Youre seeking a rejuvenating facial,a soothing massage body treatment",
  },
];
const Card = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const maxSlides = 4;

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % maxSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + maxSlides) % maxSlides);
  };
  return (
    <div>
      <div className="flex flex-col items-center mx-4">
        <div className="flex flex-col lg:flex-row gap-5 items-center">
          {cardsection
            .slice(currentSlide, currentSlide + maxSlides)
            .map((element, index) => (
              <div
                className="bg-gradient-to-r from-primaryBgColor to-swipeToBgColor p-4 rounded-md shadow-md w-full lg:w-[300px] h-[200px] mb-4 lg:mb-0"
                key={index}
              >
                <h2 className="text-xl font-bold text-center mx-auto mt-8">
                  {element.heading}
                </h2>

                <p className="text-subHeadingColor text-center mx-auto mt-4">
                  {element.para}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
