import React from 'react';
import quote_2 from '../../assets/quote_2.png'

const TestimonialCard = ({ imageSource, name, role, description }) => {
    return (
        <div className='max-w-sm h-[320px] flex flex-col items-center justify-center 
        mx-auto bg-gray-100  bg-cover bg-no-repeat
        overflow-hidden shadow-md p-5 mb-4' 
        style={{ backgroundImage: `url(${quote_2})` }}>
            <div className='mb-4'>
                <img src={imageSource} alt="Testimonial" />
            </div>
            <div className='mb-4'>
                <p className='text-gray-700  text-justify'>{description}</p>
            </div>
            <div className='text-lg font-semibold mb-2'>{name}</div>
            <div className='text-primaryBgColor0 text-sm font-medium'>{role}</div>
        </div>
    );
};

export default TestimonialCard;