import React from "react";
import shape from "../../assets/shape.svg";
import { FaApple, FaArrowRight } from "react-icons/fa";
import price_1 from "../../assets/price_1_1.jpg";
import CTAButton from "./Button";
import price_2 from "../../assets/price_1_2.jpg";

const PriceCard = () => {
  return (
    <div className="mx-5 md:mx-auto ">
      <div className="flex flex-col items-center mt-5 mx-auto">
        <div className="text-primaryColor font-sm text-xl font-serif text-center md:text-left">
          Pricing Package
        </div>

        <div className="mt-4">
          <img src={shape} alt="Shape" />
        </div>

        <div className="mt-7 mb-12">
          <p className="text-black text-4xl font-semibold">
            Choose Your Perfect Package
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-evenly items-center mb-12">
        <div className="max-w-full md:mx-2 overflow-hidden shadow-lg rounded-md bg-white p-6 mb-6 md:mb-0">
          <div className="flex flex-col md:flex-row gap-12">
            <div>
              <img
                src={price_1}
                alt="Price_1"
                className="rounded-full  object-cover"
              />
            </div>
            <div className="mx-auto">
              <div>
                <h1 className="text-2xl font-bold">Basic Package</h1>
              </div>
              <div className="mt-6">
                <div className="flex flex-row gap-2">
                  <FaApple />
                  <p>60-min Swedish Massage</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>30-Min Back Massage</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Scalp Massage</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Reflexology</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Aromatherapy Massage</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Mini Manicure</p>
                </div>

                <div className="text-xl md:text-3xl font-extrabold text-primaryColor tracking-wider mt-7 text-center">
                  BOOK NOW
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-full md:mx-2 overflow-hidden shadow-lg rounded-md bg-white p-6">
          <div className="flex flex-col md:flex-row gap-12">
            <div>
              <img
                src={price_2}
                alt="Price_2"
                className="rounded-full  object-cover"
              />
            </div>

            <div className="mx-auto">
              <div>
                <h1 className="text-2xl font-bold">Standard Package</h1>
              </div>
              <div className="mt-6">
                <div className="flex flex-row gap-2">
                  <FaApple />
                  <p>Spa Manicure & Pedicure</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Hot Stone Massage</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Body Scrub & Wrap</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Detoxifying Mud Wrap</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Couple Massage</p>
                </div>
                <div className="flex flex-row gap-2 mt-3">
                  <FaApple />
                  <p>Private Relaxation Suite Massage</p>
                </div>

                <div className="text-xl md:text-3xl font-extrabold text-primaryColor tracking-wider mt-7 text-center">
                  BOOK NOW
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
