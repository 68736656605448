import React from "react";
import CTAButton from "../components/Homepage/Button";
import { FaArrowRight } from "react-icons/fa";
import massage from "../assets/massage.jpg";
import girl from "../assets/girl.jpg";
import rightarr from "../assets/rightarr.svg";
import CardSection from "../components/Aboutpage/CardSection";
import Feedback from "../components/Aboutpage/Feedback";
import FormSection from "../components/Aboutpage/FormSection";
import Experience from "../components/Aboutpage/Experience";
import headerImg from "../assets/saloonHeadImg.png";

const About = () => {
  return (
    <div className="">
      <div
        className="h-[300px] bg-cover bg-no-repeat
                 bg-gradient-to-r from-gray-300 to-gray-200 
                "
        style={{ backgroundImage: `url(${headerImg})` }}
      >
        <div
          className="flex flex-col justify-center items-center
                 lg:items-start mt-8 mx-4 lg:mt-28 lg:ml-24"
        >
          <div>
            <p className="text-4xl font-semibold text-center lg:text-left tracking-wider">
              About Us
            </p>
          </div>
          <div className="mt-6">
            <div
              className="flex flex-row items-center 
                        justify-center lg:justify-start gap-4"
            >
              <p className="text-2xl">Home</p>
              <FaArrowRight />
              <p className="text-2xl">About</p>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div
        className="w-full h-fit
            flex flex-col md:flex-row justify-evenly
            bg-gradient-to-r from-primaryBgColor to-swipeToBgColor gap-4 md:gap-12 py-8 md:mt-16 md:mb-16"
      >
        <div className="relative mt-4 md:mt-8 mb-10 md:mb-20 mx-4 md:ml-20 lg:ml-40 md:mr-10 md:w-full">
          <img
            src={massage}
            alt="Massage"
            className="object-contain w-full h-auto"
          />
        </div>

        <div className="flex flex-col items-center justify-center mx-4 md:mx-10 md:w-full">
          <div className="mt-4 md:mt-6">
            <p className="text-xl md:text-3xl lg:text-4xl font-extrabold tracking-wide leading-tight md:leading-10 text-center md:text-left">
              Discover The <span className="text-primaryColor">Ultimate Spa</span> Massage Experience
            </p>
          </div>

          <div className="text-subHeadingColor text-sm md:text-base lg:text-lg text-center md:text-justify mt-4 md:mt-6">
            A spa experience is a sublime journey into relaxation, a sanctuary where stress dissipates and serenity takes over. Pampered by skilled hands amidst a tranquil ambiance, it's a holistic retreat that rejuvenates both body and soul, leaving you refreshed, revitalized, and thoroughly indulged.
          </div>

          <div className="text-subHeadingColor text-sm md:text-base lg:text-lg text-center md:text-left mt-4 md:mt-6">
            We also offer private rooms for more intimate treatments and couples' packages for a romantic escape.
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <Experience />

      {/* Section4 */}
      <Feedback />

      {/*Footer Section */}
    </div>
  );
};

export default About;
