import React from "react";
import shape from "../../assets/shape.svg";
import TestimonialCard from "./TestimonialCard";
import testi_1 from "../../assets/testi_1.jpg";
import testi_2 from "../../assets/testi_2.jpg";
import testi_3 from "../../assets/testi_3.jpg";

const Feedback = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-gradient-to-r from-primaryBgColor to-swipeToBgColor">
      <div className="flex flex-col w-full md:w-[55%]  items-center mb-10">
        <div className="mt-7">
          <p className="text-primaryColor font-sm text-xl font-serif text-center md:text-left">
            Testimonial
          </p>
        </div>
        <div className="mt-4">
          <img src={shape} alt="Shape" />
        </div>
        <div className="mt-6">
          <p className="text-5xl text-black tracking-wider">
            Our Customer Feedback
          </p>
        </div>
      </div>

      <div
        className="flex flex-col md:flex-row gap-10 
            mb-10 items-center justify-center"
      >
        <TestimonialCard
          imageSource={testi_1}
          name="Angelina Margaret"
          role="Customer"
          description="Exceptional attention to detail, a serene atmosphere, and skilled therapists make this spa a haven for those in pursuit of both relaxation and rejuvenation."
        />

        <TestimonialCard
          imageSource={testi_2}
          name="Senjuti Das"
          role="Customer"
          description="Unparalleled relaxation awaits at this spa haven; from the moment you step in, you're greeted with warmth, and the skilled therapists ensure an unparalleled wellness experience."
        />

        <TestimonialCard
          imageSource={testi_3}
          name="Jenifer Lopez"
          role="Customer"
          description="Each visit to this spa is a sensory delight, with skilled professionals, calming ambiance, and a diverse range of treatments that cater to every need."
        />
      </div>
    </div>
  );
};

export default Feedback;
