import React from "react";
import { FaUser, FaTag } from "react-icons/fa";

const BlogCard = ({ imageSrc, date, author, category, title }) => {
  return (
    <div className="max-w-sm mx-auto bg-white shadow-lg rounded-md overflow-hidden">
      <img src={imageSrc} alt="Blog_1" className="w-full h-64 object-cover" />
      <div className="p-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex flex-col items-center md:items-start bg-red-200 p-4 rounded-md">
            <p className="text-3xl md:text-4xl">{date}</p>
            <p className="text-center  md:text-left">July2023</p>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row justify-evenly md:justify-start gap-3">
              <div className="flex flex-row gap-3 items-center">
                <FaUser />
                <p>{`By ${author}`}</p>
              </div>
              <div className="flex flex-row gap-3 items-center">
                <FaTag />
                <p>{category}</p>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-xl font-semibold text-center md:text-left">
                {title}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
